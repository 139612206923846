import React, { useEffect, useMemo } from 'react'
import { DELETE, TICK_ICON } from '../../../icons';
import { useGetStyleFabricTemplateByIdQuery } from '../../../redux/ErpServices/StyleFabricTemplateServices';
import { DropdownWithSearch } from '../../../Inputs';

const StyleItem = ({ valueIndex, readonly, handleChange, styleData, styleFabricData, value, templateFields, handleChangeOnSubGrid,
    getConsumption, setCurrentSelectedStyleId, getSubGridValue, currentSelectedStyleId, deleteRow, setStyleItemDetails
}) => {
    const { data: styleTemplateData } = useGetStyleFabricTemplateByIdQuery(value.fabricId, { skip: (!value.fabricId) })

    const matchedTemplate = useMemo(() => styleTemplateData?.data, [styleTemplateData]);

    useEffect(() => {
        if (readonly) return
        if (!matchedTemplate) return
        setStyleItemDetails(prev => {
            if (prev[valueIndex]?.id) return prev;
            let newItems = structuredClone(prev);
            newItems[valueIndex]['fabricCostingDetails'] = (matchedTemplate?.fabricCostingDetails || []).map(i => ({ ...i, refetch: true }));
            const totalFabricLoss = newItems[valueIndex]['fabricCostingDetails'].reduce((a, c) => (a + parseFloat(c?.loss || 0)), 0);
            newItems[valueIndex]['yarnCostingDetails'] = (matchedTemplate?.yarnCostingDetails || []).map(i => ({ ...i, loss: totalFabricLoss, refetch: true }));
            return newItems
        })
    }, [readonly, matchedTemplate, setStyleItemDetails, valueIndex])
    return (
        <tr key={valueIndex} className="border-b border-gray-400 text-[11px] ">
            <td className=" text-[11px]  justify-center border-x border-gray-400">
                <select
                    disabled={readonly}
                    name="name" className='w-full md:col-span-2 col-span-1 rounded'
                    value={value.styleId}
                    required
                    onChange={(e) => { handleChange(valueIndex, "styleId", e.target.value); }} >
                    <option value="" disabled>Select</option>
                    {(styleData?.data ? styleData?.data : []).map((option, index) =>
                        <option key={index} value={option.id} >
                            {option.name}
                        </option>)}
                </select>
            </td>
            <td className=" text-[11px] flex items-center justify-center">
                <DropdownWithSearch value={value?.fabricId}
                    readOnly={readonly}
                    setValue={(value) => handleChange(valueIndex, "fabricId", value)}
                    options={(styleFabricData?.data ? styleFabricData?.data : [])} className='min-w-[250px]' />
            </td>
            <td className=" text-[11px]  border-x border-gray-400">
                <div className='flex items-center justify-center'>
                    <input
                        type="checkbox" checked={value?.isTrimFabric || false} onChange={(e) => { handleChange(valueIndex, "isTrimFabric", e.target.checked) }} disabled={readonly} />
                </div>
            </td>
            {templateFields.map((field, fieldIndex) => (
                <td key={field.id} className='border-x border-gray-400 text-right'>
                    <input type='text'
                        onFocus={(e) => { e.target.select() }}
                        min={0} className='w-full text-right' value={getSubGridValue(valueIndex, fieldIndex)}
                        onChange={(e) => { handleChangeOnSubGrid(valueIndex, fieldIndex, e.target.value) }}
                        disabled={readonly || field.formula}
                    />
                </td>
            ))}
            <td className='border-x border-gray-400 text-right'>
                {getConsumption(valueIndex)}
            </td>
            <td className='border-x border-gray-400'>
                <input value={value?.manualConsumption} type='text' className='w-full text-right'
                    disabled={readonly}
                    onChange={(e) => { handleChange(valueIndex, "manualConsumption", e.target.value) }} />
            </td>
            <td className='border-x border-gray-400 text-right'>
                {value?.consumptionQty}
            </td>
            <td className="flex justify-center items-center text-[11px] text-green-600 w-full border-black"
                onClick={() => {
                    setCurrentSelectedStyleId(valueIndex)
                }}
            >
                <button type='button' className='border-black w-full min-h-[30px]'>
                    {(parseInt(valueIndex) === parseInt(currentSelectedStyleId)) && TICK_ICON}
                </button>
            </td>
            {!readonly &&
                <td className="border border-gray-400 text-[11px] text-center">
                    <button
                        type='button'
                        onClick={() => {
                            deleteRow(valueIndex)
                        }}
                        className='text-[11px] text-red-600 '>{DELETE}
                    </button>
                </td>
            }
        </tr>
    )
}

export default StyleItem
