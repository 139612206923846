import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: "10px 0",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderRow: {
    flexDirection: "row",
    borderBottomColor: "#bdbdbd",
    borderBottomWidth: 1,
  },
  tableColHeader: {
    width: "12%",
    padding: 4,
    backgroundColor: "#4D5865",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
  },
  tableColHeaderFabric: {
    width: "32%",
    padding: 4,
    backgroundColor: "#4D5865",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
  },
  tableCol: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
    textAlign: "left",
  },
  tableColWide: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
  },
  tableColWider: {
    width: "53%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
    textAlign: "left",
  },
  tableCellHeader: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  tableCellLeft: {
    fontSize: 8,
    color: "#333",
    textAlign: "left",
    paddingTop: 2,
    paddingBottom: 2,
  },
  tableCellRight: {
    fontSize: 8,
    color: "#333",
    textAlign: "right",
    paddingTop: 2,
    paddingBottom: 2,
  },
  header: {
    fontSize: 12,
    marginBottom: 3,
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    textDecoration: "underline",
  },
  alternateRow: {
    backgroundColor: "#f9f9f9",
  },
});

const StyleDetail = ({
  templateDetails,
  styleItemDetails,
  CostingStyleItemDetails,
}) => (
  <View>
    {console.log(CostingStyleItemDetails, "CostingStyleItemDetails")}
    <Text style={styles.header}>Style Details</Text>

    <View style={styles.table}>
      <View style={styles.tableHeaderRow}>
        <View style={styles.tableColHeader}>
          <Text>Style</Text>
        </View>
        <View style={styles.tableColHeaderFabric}>
          <Text>Fabric</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text>Trim Fabric</Text>
        </View>
        {templateDetails?.ConsumptionTemplateDetails?.length > 0 &&
          templateDetails.ConsumptionTemplateDetails.map((header, index) => (
            <View key={index} style={styles.tableColHeader}>
              <Text>{header?.FieldMaster?.name?.toLowerCase() || "N/A"}</Text>
            </View>
          ))}
        <View style={styles.tableColHeader}>
          <Text>Consumption</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text>Manual Cons</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text>Cad.Weight</Text>
        </View>
      </View>
      {CostingStyleItemDetails?.map((item, index) => (
        <View
          style={[
            styles.tableRow,
            index % 2 === 0 ? styles.alternateRow : null,
          ]}
          key={index}
        >
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellLeft}>
              {styleItemDetails?.[index]?.Style?.name || ""}
            </Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableCellLeft}>
              {styleItemDetails?.[index]?.StyleFabricTemplate?.name || ""}
            </Text>
          </View>

          <View style={styles.tableColWide}>
            <Text style={styles.tableCellLeft}>
              {styleItemDetails?.[index]?.isTrimFabric ? "Yes" : "No"}
            </Text>
          </View>
          {item?.templateFields?.map((data, idx) => (
            <View key={idx} style={styles.tableColWide}>
              <Text style={styles.tableCellRight}>{data?.value || ""}</Text>
            </View>
          ))}
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>
              {styleItemDetails?.[index]?.consumption || ""}
            </Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>
              {styleItemDetails?.[index]?.manualConsumption || ""}
            </Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>
              {styleItemDetails?.[index]?.consumptionQty || ""}
            </Text>
          </View>
        </View>
      ))}
    </View>
  </View>
);

export default StyleDetail;
