import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import CostingDetailsSection from "./CostingDetailSection";
import StyleDetail from "./StyleDetail";
import CostingSummary from "./CostingSummay";
import Commercial from "./Commercial";
import OverallCosting from "./OverallCostingSummary";
import YarnDet from "./Yarn";
import FabricDet from "./Fabric";
import Trim from "./Trim";
import CmpDet from "./Cmp";
import { getDateFromDateTimeToDisplay } from "../../../../Utils/helper";
import tw from "../../../../Utils/tailwind-react-pdf copy";

const styles = StyleSheet.create({
  page: {
    padding: "10px",
    fontSize: "10px",
    fontFamily: "Helvetica",
    backgroundColor: "#F3F4F6",
    color: "#374151",
  },
  outerContainer: {
    padding: "5px",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    marginBottom: "15px",
  },
  sectionHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "15px",
    textAlign: "center",
    color: "#111827",
    borderBottom: "1px solid #3B82F6",
    paddingBottom: "8px",
  },
  fieldGroup: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 3px",
    borderBottom: "1px solid #E5E7EB",
    alignItems: "center",
    gap: "3px",
  },
  fieldLabel: {
    color: "#6B7280",
    fontWeight: "bold",
    fontSize: "10px",
    flex: 1,
  },
  fieldValue: {
    color: "#1F2937",
    fontSize: "9px",
    textAlign: "right",
    flex: 1,
  },
  section: {
    marginVertical: "10px",
    backgroundColor: "#F9FAFB",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #E5E7EB",
  },
  remarksGroup: {
    paddingTop: "15px",
    marginTop: "10px",
    borderTop: "1px solid #D1D5DB",
  },
  footer: {
    marginTop: "20px",
    fontSize: "11px",
    textAlign: "center",
    color: "#9CA3AF",
    borderTop: "1px solid #D1D5DB",
    paddingTop: "12px",
  },
});

const CostingPrintFormat = ({
  data,
  version,
  templateDetails,

  styleItemDetails, conversionValue, commercialCostingDetails, targetRate,
  garmentLossPercent, setGarmentLossPercent, CostingStyleItemDetail,
  transportCostPercent, setTransportCostPercent, readOnly, setTargetRate
}) => (
  <Document>

    <Page size="A4" style={styles.page} orientation="portrait">
      <View style={styles.outerContainer}>
        <CostingDetailsSection data={data} version={version} />
        <StyleDetail
          templateDetails={templateDetails ? templateDetails.data : []}
          styleItemDetails={styleItemDetails}
          CostingStyleItemDetails={data?.CostingStyleItemDetails || []}
        />
        <View style={tw("")}>
          <YarnDet
            CostingStyleItemDetail={
              data?.CostingStyleItemDetails || []
            } data={data}
          />
          <FabricDet
            CostingStyleItemDetail={
              data?.CostingStyleItemDetails || []
            } data={data}
          />
          <Trim
            CostingStyleItemDetail={
              data?.CostingStyleItemDetails || []
            } data={data}
          />
          < CmpDet
            CostingStyleItemDetail={
              data?.CostingStyleItemDetails || []
            } data={data}
          />
          <CostingSummary CostingStyleItemDetail={
            data?.CostingStyleItemDetails || []
          } data={data}
          />
          <Commercial commercialCostingDetails={commercialCostingDetails} targetRate={targetRate} data={data}
          />
          <OverallCosting data={data} templateDetails={templateDetails} styleItemDetails={styleItemDetails} commercialCostingDetails={commercialCostingDetails} targetRate={targetRate}
            garmentLossPercent={garmentLossPercent} setGarmentLossPercent={setGarmentLossPercent}
            transportCostPercent={transportCostPercent} setTransportCostPercent={setTransportCostPercent}
            setTargetRate={setTargetRate} />
        </View>
        <Text style={styles.footer}>
          Generated on: {getDateFromDateTimeToDisplay(new Date())}
        </Text>
      </View>
    </Page>
  </Document>
);

export default CostingPrintFormat;
