import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import React from 'react';
import { getImageUrlPath } from '../../../../Constants';

const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 4,
    color: '#0f172a',
    borderBottom: '2 solid #6b7280',
    paddingBottom: 2,
    letterSpacing: 1,
  },
  section: {
    padding: 4,
    border: '1 solid #d1d5db',
    borderRadius: 6,
    backgroundColor: '#f9fafb',
    marginBottom: 5,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  photoContainer: {
    width: '15%',
    height: 80,
    marginRight: 10,
    borderRadius: 4,
    border: '1 solid #e5e7eb',
    padding: 2,
    backgroundColor: '#f3f4f6',
  },
  photo: {
    width: '80%',
    height: '80%',
    objectFit: 'contain',
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fieldGroup: {
    width: '33%',
  },
  fieldLabel: {
    fontSize: 8,
    fontWeight: 'bold',
    color: '#6F7280',
    marginBottom: 2,
  },
  fieldValue: {
    fontSize: 8,
    color: '#111827',
    marginBottom: 8,
  },
});

const CostingDetailsSection = ({ data, version }) => (
  <View wrap={false}>
    <Text style={styles.sectionHeader}>Costing Details</Text>
    <View style={styles.section}>
      <View style={styles.contentContainer}>
        <View style={styles.photoContainer}>
          <Image style={styles.photo} src={getImageUrlPath(data ? data?.styleImage : '')} />
        </View>

        <View style={styles.textContainer}>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Doc ID:</Text>
            <Text style={styles.fieldValue}>{data ? data?.docId : ''}</Text>

            <Text style={styles.fieldLabel}>Customer:</Text>
            <Text style={styles.fieldValue}>{data ? data?.Party?.aliasName : ''}</Text>

            <Text style={styles.fieldLabel}>Order Qty:</Text>
            <Text style={styles.fieldValue}>{data ? data?.orderQty : ''}</Text>


          </View>

          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Date:</Text>
            <Text style={styles.fieldValue}>
              {data ? new Date(data.createdAt).toISOString().split('T')[0] : ''}
            </Text>

            <Text style={styles.fieldLabel}>Style Ref No:</Text>
            <Text style={styles.fieldValue}>{data ? data?.styleRefNo : ''}</Text>

            <Text style={styles.fieldLabel}>Ratio:</Text>
            <Text style={styles.fieldValue}>{data ? data?.Ratio?.name : ''}</Text>


          </View>

          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Currency:</Text>
            <Text style={styles.fieldValue}>{data ? data?.Currency?.name : ''}</Text>

            <Text style={styles.fieldLabel}>Conversion Value:</Text>
            <Text style={styles.fieldValue}>{data ? data?.conversionValue : ""}</Text>

            <Text style={styles.fieldLabel}>Version:</Text>
            <Text style={styles.fieldValue}>{version ? version : ''}</Text>


          </View>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Size Temp:</Text>
            <Text style={styles.fieldValue}>{data ? data?.SizeTemplate?.name : ''}</Text>

            <Text style={styles.fieldLabel}>Inco. Term:</Text>
            <Text style={styles.fieldValue}>{data ? data?.IncoTerm?.name : ''}</Text>

            <Text style={styles.fieldLabel}>Remarks:</Text>
            <Text style={styles.fieldValue}>{data ? data?.remarks : ''}</Text>
          </View>
        </View>
      </View>
    </View>
  </View>
);

export default CostingDetailsSection;
