import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { priceWithTax } from '../../../../Utils/helper';
import { findFromList } from '../../../../Utils/helper';

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "48%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginVertical: 10,
  },
  tableContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  totalRow: {
    backgroundColor: "#E5E7EB",
  },
  tableTotalCell: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "right",
    padding: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderRow: {
    flexDirection: "row",
    borderBottomColor: "#bdbdbd",
    borderBottomWidth: 1,
  },
  tableColHeader: {
    width: "33.33%",
    padding: 4,
    backgroundColor: "#4D5865",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
  },
  tableCol: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
    textAlign: "left",
  },
  tableColWide: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
    fontSize: 8,

  },
  garment: {
    backgroundColor: "#16A34A",
    color: "white",
    padding: 2,
  },
  margin: {
    backgroundColor: "#2563EB",
    color: "white",
    padding: 2,
  },
  fob: {
    backgroundColor: "#7C3AED",
    color: "white",
    padding: 2,
  },
  tableTotal: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  tableCellHeader: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  tableCell: {
    fontSize: 8,
    color: "#333",
    textAlign: "left",
    paddingTop: 2,
    paddingBottom: 2,
  },
  header: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    padding: 2,
    backgroundColor: "#E5E7EB",
  },
  alternateRow: {
    backgroundColor: "#f9f9f9",
  },
  tableCellRight: {
    fontSize: 8,
    color: "#333",
    textAlign: "right",
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const OverallCosting = ({
  data,
  styleItemDetails,
  conversionValue,
  commercialCostingDetails,
  targetRate,
  garmentLossPercent,
  setGarmentLossPercent,
  CostingStyleItemDetail,
  transportCostPercent,
  setTransportCostPercent,
  readOnly,
  setTargetRate,
}) => {
  function getStyleWiseTotal(styleId) {
    const currentStyleItems = styleItemDetails.filter(
      (i) => parseInt(i.styleId) === parseInt(styleId)
    );
    const summary = [
      {
        name: "Yarn",
        value: currentStyleItems.reduce(
          (a, currentStyleItem) =>
            a +
            (currentStyleItem?.yarnCostingDetails || []).reduce(
              (a, c) =>
                a +
                priceWithTax(
                  (parseFloat(currentStyleItem.consumptionQty) || 0) * c.rate,
                  parseFloat(c.loss || 0)
                ),
              0
            ),
          0
        ),
      },
      {
        name: "Fabric",
        value: currentStyleItems.reduce(
          (a, currentStyleItem) =>
            a +
            (currentStyleItem?.fabricCostingDetails || []).reduce(
              (a, c) =>
                a +
                priceWithTax(
                  (parseFloat(currentStyleItem.consumptionQty) || 0) * c.rate,
                  parseFloat(c.loss || 0)
                ),
              0
            ),
          0
        ),
      },
      {
        name: "Trims",
        value: currentStyleItems.reduce(
          (a, currentStyleItem) =>
            a +
            (currentStyleItem?.accessoryCostingDetails || []).reduce(
              (a, c) =>
                a +
                priceWithTax(
                  (parseFloat(c.consumptionQty) || 0) * c.rate,
                  parseFloat(c.loss || 0)
                ),
              0
            ),
          0
        ),
      },
      {
        name: "CMT",
        value: currentStyleItems.reduce(
          (a, currentStyleItem) =>
            a +
            (currentStyleItem?.cmtCostingDetails || []).reduce(
              (a, c) =>
                a +
                priceWithTax(
                  (parseFloat(c.consumptionQty) || 0) * c.rate,
                  parseFloat(c.loss || 0)
                ),
              0
            ),
          0
        ),
      },
    ];
    return summary.reduce((a, c) => a + parseFloat(c.value), 0);
  }

  function getAmount(type, rate) {
    if (type === "Percentage")
      return (
        (parseFloat(targetRate || 0) / data.conversionValue / 100) *
        parseFloat(rate)
      );
    if (type === "Flat") return parseFloat(rate);
    return 0;
  }

  let distinctStyleItems = [
    ...new Set(styleItemDetails.map((i) => parseInt(i.styleId))),
  ];

  let overAllSummary = distinctStyleItems.map((styleId) => ({
    name: styleItemDetails.find((i) => i.styleId === styleId)?.Style?.name,
    value: getStyleWiseTotal(styleId).toFixed(3),
  }));

  const totalAmount = overAllSummary.reduce((a, c) => a + parseFloat(c.value), 0);

  overAllSummary = [
    ...overAllSummary,
    {
      name: "Commercial",
      value: commercialCostingDetails
        .reduce((a, c) => a + getAmount(c.type, c.rate), 0)
        .toFixed(3),
    },
  ];

  const total = overAllSummary.reduce((a, c) => a + parseFloat(c.value), 0);

  const garmentCost = ((garmentLossPercent || 0) * totalAmount) / 100;
  const transportCost = parseFloat(transportCostPercent || 0);
  const overAllTotal = total + garmentCost + transportCost;

  const fobValueIndianValue = isFinite(parseFloat(targetRate) / data?.conversionValue)
    ? parseFloat(targetRate) / data?.conversionValue
    : 0;

  const margin = fobValueIndianValue - overAllTotal;

  const marginPercent = isFinite(margin / fobValueIndianValue)
    ? (margin / fobValueIndianValue) * 100
    : 0;

  return (
    <View style={styles.tableContainer}>
      <View style={styles.table} wrap={false}>
        <Text style={styles.header} >Overall Summary</Text>

        <View style={styles.tableHeaderRow}>
          {["Style", "Amount", "Conv.value"].map((header, i) => (
            <View style={styles.tableColHeader} key={i}>
              <Text>{header}</Text>
            </View>
          ))}
        </View>

        {overAllSummary.map((itemDetail, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableColWide}>
              <Text style={styles.tableCell}>{itemDetail?.name || ""}</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableCellRight}>
                {parseFloat(itemDetail.value).toFixed(3)}
              </Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableCellRight}>
                {(parseFloat(itemDetail?.value || 0) * (data?.conversionValue || 0)).toFixed(3)}
              </Text>
            </View>
          </View>
        ))}

        <View style={styles.tableRow}>
          <View style={styles.tableColWide}>
            <Text>Garment Loss %</Text>
            <input
              type="text"
              value={garmentLossPercent}
              onChange={(e) => setGarmentLossPercent(e.target.value)}
              disabled={readOnly}
            />
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{garmentCost.toFixed(3)}</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{(garmentCost * data?.conversionValue).toFixed(3)}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColWide}>
            <Text>Transport Cost</Text>
            <input
              type="text"
              value={transportCostPercent}
              onChange={(e) => setTransportCostPercent(e.target.value)}
              disabled={readOnly}
            />
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{transportCost.toFixed(3)}</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{(transportCost * data?.conversionValue).toFixed(3)}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColWide}>
            <Text>Costing</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{overAllTotal.toFixed(3)}</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{(overAllTotal * data?.conversionValue).toFixed(3)}</Text>
          </View>
        </View>
      </View>

      <View style={styles.table} wrap={false}>
        <Text style={styles.header}>Comparison</Text>
        <View style={styles.tableRow}>
          <View style={styles.tableColWide}>
            <Text style={styles.garment}>Garment Cost</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{(overAllTotal).toFixed(3)}</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{(overAllTotal * data?.conversionValue).toFixed(3)}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColWide}>
            <Text style={styles.fob}>Quote / FOB</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{fobValueIndianValue.toFixed(3)}</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{fobValueIndianValue.toFixed(3)}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColWide}>
            <Text style={styles.margin}>Margin</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{parseFloat(margin).toFixed(3)}</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{(margin * data?.conversionValue).toFixed(3)}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColWide}>
            <Text style={styles.margin}>Margin %</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{marginPercent.toFixed(3)}</Text>
          </View>
          <View style={styles.tableColWide}>
            <Text style={styles.tableCellRight}>{marginPercent.toFixed(3)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default OverallCosting;
