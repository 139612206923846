import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { priceWithTax } from "../../../../Utils/helper";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",

    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: "10px 0",
  },
  tableHalf: {
    display: "table",
    width: "48%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: "10px 1%",
  },
  totalRow: {
    backgroundColor: "#E5E7EB",
  },
  tableTotalCell: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "right",
    padding: 1,
    color: "black"
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderRow: {
    flexDirection: "row",
    borderBottomColor: "#bdbdbd",
    borderBottomWidth: 1,
  },
  tableColHeader: {
    width: "100%",
    padding: 4,
    backgroundColor: "#4D5865",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
  },
  tableCol: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
    textAlign: "left",
  },
  tableColWide: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
  },
  tableTotal: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  tableCellHeader: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  tableCell: {
    fontSize: 8,
    color: "#333",
    textAlign: "left",
    paddingTop: 2,
    paddingBottom: 2,
  },
  tableCellRight: {
    fontSize: 8,
    color: "#333",
    textAlign: "right",
    paddingTop: 2,
    paddingBottom: 2,
  },
  header: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    padding: 2,
    backgroundColor: "#E5E7EB",
  },
  alternateRow: {
    backgroundColor: "#f9f9f9",
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const CostingSummaryStyleItem = ({ CostingStyleItemDetail, styleId, data }) => {

  const currentStyleItems = CostingStyleItemDetail.filter(i => parseInt(i?.styleId || 0) === parseInt(styleId || 0))

  const summary = [
    {
      name: "Yarn",
      value: currentStyleItems.reduce((acc, currentStyleItem) => acc + (currentStyleItem?.yarnCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(currentStyleItem?.consumptionQty || 0)) * c.rate, parseFloat(c?.loss || 0))), 0), 0)
    }, {
      name: "Fabric",
      value: currentStyleItems.reduce((acc, currentStyleItem) => acc + (currentStyleItem?.fabricCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(currentStyleItem?.consumptionQty || 0)) * c.rate, parseFloat(c?.loss || 0))), 0), 0)
    }, {
      name: "Trims",
      value: currentStyleItems.reduce((acc, currentStyleItem) => acc + (currentStyleItem?.accessoryCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(c?.consumptionQty || 0)) * c.rate, parseFloat(c?.loss || 0))), 0), 0)
    }, {
      name: "CMP",
      value: currentStyleItems.reduce((acc, currentStyleItem) => acc + (currentStyleItem?.cmtCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(c?.consumptionQty || 0)) * c.rate, parseFloat(c?.loss || 0))), 0), 0)
    }
  ]


  return (
    <View wrap={false}>
      <View
        style={[
          styles.rowContainer,
        ]}
      >
        <View
          style={styles.table}
        >
          <Text style={styles.header}>Costing Summary</Text>
          <View style={styles.tableHeaderRow}>
            {["Process", "Amount", "Conv.value"].map((header, index) => (
              <View style={styles.tableColHeader} key={index}>
                <Text>{header}</Text>
              </View>
            ))}
          </View>

          <View>
            {summary?.map((item, index) => {
              return (
                <View
                  style={[
                    styles.tableRow,
                    index % 2 === 0 ? styles.alternateRow : null,
                  ]}
                  key={index}
                  wrap={false}

                >
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableCell}>{item?.name || ""}</Text>
                  </View>
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableCellRight}>
                      {item?.value.toFixed(3) || ""}
                    </Text>
                  </View>
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableCellRight}>
                      {(parseFloat(item.value) * data.conversionValue).toFixed(3)}
                    </Text>
                  </View>
                </View>
              );
            })}

            <View style={[styles.tableRow, styles.totalRow]}>
              <View style={styles.tableColWide}>
                <Text style={styles.tableTotalCell}>Total Costing</Text>
              </View>

              <View style={styles.tableColWide}>
                <Text style={styles.tableTotalCell}>
                  {summary.reduce((a, c) => a + parseFloat(c.value), 0).toFixed(3)}
                </Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableTotalCell}>
                  {(
                    summary.reduce((a, c) => a + parseFloat(c.value), 0) *
                    parseFloat(data.conversionValue)
                  ).toFixed(3)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

function CostingSummary({ CostingStyleItemDetail, data }) {
  let uniqueStyleIdArr = [...new Set(CostingStyleItemDetail.map(i => i.styleId))];
  return (
    <>
      {uniqueStyleIdArr.map(styleId =>
        <CostingSummaryStyleItem key={styleId} CostingStyleItemDetail={CostingStyleItemDetail} styleId={styleId} data={data} />
      )}
    </>
  )
}


export default CostingSummary;
