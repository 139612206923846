import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",

    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: "10px 0",
  },
  totalRow: {
    backgroundColor: "#E5E7EB",
  },
  tableTotalCell: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "right",
    padding: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderRow: {
    flexDirection: "row",
    borderBottomColor: "#bdbdbd",
    borderBottomWidth: 1,
  },
  tableColHeader: {
    width: "100%",
    padding: 4,
    backgroundColor: "#4D5865",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
  },
  tableColWide: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
  },
  tableTotal: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  tableCell: {
    fontSize: 8,
    color: "#333",
    textAlign: "left",
    paddingTop: 2,
    paddingBottom: 2,
  },
  header: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    padding: 2,
    backgroundColor: "#E5E7EB",
  },
  tableCellRight: {
    fontSize: 8,
    color: "#333",
    textAlign: "right",
    paddingTop: 2,
    paddingBottom: 2,
  },
  alternateRow: {
    backgroundColor: "#f9f9f9",
  },
});

const Commercial = ({ targetRate, data, commercialCostingDetails }) => {
  const conversionValue = data?.conversionValue || 1;

  function getAmount(type, rate) {
    const totalAmount = targetRate && data?.conversionValue ? parseFloat(targetRate || 0) / data.conversionValue : 0;
    if (type === "Percentage") return (totalAmount / 100) * parseFloat(rate);
    if (type === "Flat") return parseFloat(rate);
    return 0;
  }

  const total = commercialCostingDetails.reduce(
    (accumulator, current) => accumulator + getAmount(current.type, current.rate),
    0
  );

  return (
    <View>
      <View style={styles.tableHalf} wrap={false}>
        <Text style={styles.header}>Commercial</Text>
        <View style={styles.tableHeaderRow}>
          {["Process", "Type", "Rate", "Amount", "Conv.Value"].map((header, index) => (
            <View style={styles.tableColHeader} key={index}>
              <Text>{header}</Text>
            </View>
          ))}
        </View>

        <View>
          {commercialCostingDetails.map((itemDetail, index) => (
            <View
              style={[styles.tableRow, index % 2 === 0 ? styles.alternateRow : null]}
              key={index}
              wrap={false}

            >
              <View style={styles.tableColWide}>
                <Text style={styles.tableCell}>{itemDetail?.Process?.name || ""}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCell}>{itemDetail?.type || ""}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>{itemDetail?.rate || ""}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>
                  {(getAmount(itemDetail.type, itemDetail.rate)).toFixed(3)}
                </Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>
                  {(getAmount(itemDetail.type, itemDetail.rate) * conversionValue).toFixed(3)}
                </Text>
              </View>
            </View>
          ))}

          <View style={[styles.tableRow, styles.totalRow]}>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}></Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}></Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}></Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}>{total.toFixed(3)}</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}>{(total * conversionValue).toFixed(3)}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Commercial;
