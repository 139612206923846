import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",

    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: "10px 0",
  },
  totalRow: {
    backgroundColor: "#E5E7EB",
  },
  tableTotalCell: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "right",
    padding: 1,
    color: "black",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderRow: {
    flexDirection: "row",
    borderBottomColor: "#bdbdbd",
    borderBottomWidth: 1,
  },
  tableColHeader: {
    width: "100%",
    padding: 4,
    backgroundColor: "#4D5865",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
  },
  tableColHeaderWide: {
    width: "200%",
    padding: 4,
    backgroundColor: "#4D5865",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
    textAlign: "left",
  },
  tableColWide: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
  },
  tableColWider: {
    width: "200%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
  },
  tableColWide1: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    padding: 4,
  },

  tableTotal: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  tableCellHeader: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  tableCell: {
    fontSize: 8,
    color: "#333",
    textAlign: "left",
    paddingTop: 2,
    paddingBottom: 2,
  },
  tableCellRight: {
    fontSize: 8,
    color: "#333",
    textAlign: "right",
    paddingTop: 2,
    paddingBottom: 2,
  },

  header: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    padding: 2,
    backgroundColor: "#E5E7EB",
  },
  alternateRow: {
    backgroundColor: "#f9f9f9",
  },
});

const calculateCosting = (consumptionQty, rate, loss) => {
  const amount = (rate * consumptionQty).toFixed(3);
  const lossAmount = ((loss / 100) * amount).toFixed(3);
  const totalAmount = (parseFloat(amount) + parseFloat(lossAmount)).toFixed(3);
  return { amount, lossAmount, totalAmount };
};

const FabricDet = ({ CostingStyleItemDetail }) => {
  const allYarnDetails = CostingStyleItemDetail.flatMap(itemDetail => ([
    { process: itemDetail?.StyleFabricTemplate?.name, isHeading: true },
    ...(itemDetail?.fabricCostingDetails || []).map(item => ({
      process: item?.Process?.name || "",
      yarn: item?.Article?.name || "",
      uom: item?.Uom?.name || "",
      consumptionQty: itemDetail.consumptionQty,
      loss: item.loss || 0,
      rate: item.rate || 0,
      amount: calculateCosting(parseFloat(itemDetail.consumptionQty), parseFloat(item.rate || 0), parseFloat(item.loss || 0)).amount,
      lossAmount: calculateCosting(parseFloat(itemDetail.consumptionQty), parseFloat(item.rate || 0), parseFloat(item.loss || 0)).lossAmount,
      totalAmount: calculateCosting(parseFloat(itemDetail.consumptionQty), parseFloat(item.rate || 0), parseFloat(item.loss || 0)).totalAmount,
    }))
  ])
  );

  const totalAmount = allYarnDetails.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0).toFixed(3);
  const totalLossAmount = allYarnDetails.reduce((acc, item) => acc + (parseFloat(item.lossAmount) || 0), 0).toFixed(3);
  const totalTotalAmount = allYarnDetails.reduce((acc, item) => acc + (parseFloat(item.totalAmount) || 0), 0).toFixed(3);

  return (
    <View >
      <View style={styles.table}>
        <Text style={styles.header}>Fabric Details</Text>
        <View style={styles.tableHeaderRow}>
          {[
            "Process",
            "Uom",
            "Con.Qty",
            "Loss%",
            "Rate",
            "Amount",
            "Loss.Amt",
            "Total.Amt",
          ].map((header, index) => (
            <View
              style={[
                styles.tableColHeader,
                header === "Yarn" ? styles.tableColHeaderWide : null,
              ]}
              key={index}
            >
              <Text>{header}</Text>
            </View>
          ))}
        </View>

        {allYarnDetails.map((item, index) => (
          item.isHeading ?
            <View
              style={[styles.tableRow, index % 2 === 0 ? styles.alternateRow : null]}
              key={index}
              wrap={false}
            >
              <View style={styles.tableColWide}>
                <Text style={styles.tableCell}>{item.process}</Text>
              </View>
            </View>
            :
            <View
              style={[styles.tableRow, index % 2 === 0 ? styles.alternateRow : null]}
              key={index}
              wrap={false}

            >
              <View style={styles.tableColWide}>
                <Text style={styles.tableCell}>{item.process}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCell}>{item.uom}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>{item.consumptionQty}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>{item.loss}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>{item.rate}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>{item.amount}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>{item.lossAmount}</Text>
              </View>
              <View style={styles.tableColWide}>
                <Text style={styles.tableCellRight}>{item.totalAmount}</Text>
              </View>
            </View>
        ))}

        {/* Total Row */}
        <View style={[styles.tableRow, styles.totalRow]} wrap={false}>
          <View style={[styles.tableColWide, { flexDirection: "row", justifyContent: "flex-end" }]}>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}>Total</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}>{totalAmount}</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}>{totalLossAmount}</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableTotalCell}>{totalTotalAmount}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default FabricDet;



